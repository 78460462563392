import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TitleHeader from "../titleHeader";
import Button from "../button";
import RealestateCard from "../realestateCard";
import api from "../../api";

import { Container, ContainerRealEstate, Content } from "./styles";

const SingularRealtyHighlights = () => {
  const navigate = useNavigate();
  const [realestate, setRealEstate] = useState();

  const renderRealEstate = async () => {
    const data = {
      min_price_per_private_square_meter: 20000,
      finality: ["residential"],
      results_per_page: 3,
    };

    try {
      await api
        .get("/buildings", {
          params: data,
        })
        .then((response) => {
          setRealEstate(response.data.buildings);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  useEffect(() => {
    renderRealEstate();
  }, []);

  return (
    <Container>
      <Content>
        <TitleHeader
          title="Destaques Singular Realty"
          subtitle="Imóveis selecionados  especialmente para você"
          className="title"
        />

        <ContainerRealEstate>
          {realestate &&
            realestate.map((realestate, index) => {
              return (
                <RealestateCard
                  image={realestate.default_image["520x280"]}
                  location={realestate.address.area}
                  squareMeters={realestate.min_area}
                  parkingSpaces={realestate.min_parking}
                  dormitory={realestate.min_bedrooms}
                  value={realestate.min_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  name={realestate.name}
                  suite={realestate.min_suites}
                  onClick={() => navigate(`/imoveis/${realestate.id}`)}
                  key={index}
                />
              );
            })}
        </ContainerRealEstate>
        <Button onClick={() => navigate("/destaques-singular-realty")}>
          VEJA TODOS OS DESTAQUES
        </Button>
      </Content>
    </Container>
  );
};

export default SingularRealtyHighlights;
