import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.section`
  width: 1128px;
  display: flex;
  height: 100%;
  margin-bottom: 45px;
  margin-top: 31px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const LoadingMessage = styled.div`
  font-size: 18px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
`;
