import styled from "styled-components";

export const Container = styled.div`
  width: 264px;
  height: 380px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  background-color: white;

  &:hover {
    box-shadow: 0 6px 10px rgba(20, 32, 46, 0.2); /* Aumenta levemente no hover */
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Image = styled.div`
  width: 100%;
  margin-bottom: 13px;
  height: 147px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
`;

export const Name = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #14202e;
`;

export const Location = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #14202e;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 14px;
`;

export const Info = styled.span`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
`;

export const Value = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #14202e;
  font-weight: bold;
  margin-top: 30px;
`;
