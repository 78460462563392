import styled from "styled-components";

export const Container = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 63px;
  padding-top: 46px;
`;

export const Content = styled.div`
  width: 1128px;
  height: 100%;
  padding-top: 41px;

  button {
    margin: 0 auto;
  }

  .title {
    margin-bottom: 33px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContainerRealEstate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 47px;

  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
