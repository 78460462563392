import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.section`
  width: 1128px;
  height: 100%;
  padding-bottom: 52px;
  padding-top: 31px;

  .title {
    margin-bottom: 39px;
  }

  @media (max-width: 1200px) {
    padding: 20px 20px;
    width: 100%;
  }
`;

export const Description = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
`;
