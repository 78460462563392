import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";

import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Button from "../button";
import ImageModal from "../imageModal";

import {
  Container,
  Location,
  RealestateInfo,
  RealestateDetail,
  TypeRealestate,
  Infos,
  Value,
  ImageRealestate,
} from "./styles";

import IconSquareMeters from "../../assets/icons/squareMeters.svg";
import IconBed from "../../assets/icons/bed.svg";
import IconParkingSpaces from "../../assets/icons/parking_space.svg";

function InfosRealestate({
  latitude,
  longitude,
  name,
  location,
  value,
  parkingSpaces,
  squareMeters,
  bedroom,
  description,
  suite,
}) {
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [index, setIndex] = useState(0);

  const { id } = useParams();

  const renderModal = (index) => {
    setSelectedImage(images[index]);
    setIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNavigate = (newIndex) => {
    if (newIndex < 0) {
      setIndex(images.length - 1);
    } else if (newIndex >= images.length) {
      setIndex(0);
    } else {
      setIndex(newIndex);
    }
  };

  const fetchImages = async () => {
    try {
      api
        .get(`/buildings/${id}/images`, {
          params: { dimensions: ["2280x1800"] },
        })
        .then((response) => {
          setImages(response.data.images);
        })
        .catch((error) => {
          console.log("Erro: ", error);
        });
    } catch (error) {
      console.log("Erro na requisição: ", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <Container>
      <Carousel
        showThumbs={false}
        className="carousel"
        infiniteLoop={true}
        showStatus={false}
      >
        {images.map((image, index) => (
          <ImageRealestate
            key={index}
            style={{ backgroundImage: `url("${image["2280x1800"]}")` }}
            onClick={() => renderModal(index)}
          />
        ))}
      </Carousel>
      {isModalOpen && (
        <ImageModal
          images={images}
          index={index}
          onClose={closeModal}
          onNavigate={handleNavigate}
        />
      )}

      <RealestateInfo>
        <TypeRealestate>
          <div>
            <h2>{name}</h2>
            <span>{location}</span>
          </div>

          <Button
            backgroundColor="#25D366"
            icon="whats"
            onClick={() => window.open("https://wa.me/5511989217386", "_blank")}
          >
            Agende uma visita
          </Button>
        </TypeRealestate>

        <Value>
          {parseFloat(value.replace(/[^\d,.-]/g, "").replace(",", ".")) !==
            0.1 && <h1>{value}</h1>}
        </Value>

        <RealestateDetail>
          <Infos>
            <div>
              <img src={IconSquareMeters} alt="Área" />
              <span>{squareMeters} m²</span>
            </div>

            {suite !== 0 && (
              <div>
                <img src={IconBed} alt="Suíte" />
                <span>{suite} suíte(s)</span>
              </div>
            )}

            {parkingSpaces !== 0 && (
              <div>
                <img src={IconParkingSpaces} alt="Vaga" />
                <span>{parkingSpaces} vaga(s)</span>
              </div>
            )}

            {bedroom !== 0 && (
              <div>
                <img src={IconBed} alt="Quartos" />
                <span>{bedroom} dormitório(s)</span>
              </div>
            )}
          </Infos>

          <Button
            backgroundColor="#25D366"
            icon="whats"
            onClick={() => window.open("https://wa.me/5511989217386", "_blank")}
          >
            Agende uma visita
          </Button>
          <p>{description}</p>
        </RealestateDetail>
      </RealestateInfo>

      <Location>
        <APIProvider apiKey="AIzaSyCCNTdgrQX8NPjhxyUUVGDPcyL9nnO1jyY">
          <Map
            defaultZoom={13}
            defaultCenter={{ lat: latitude, lng: longitude }}
            mapId="891295543fa9f0b0"
          >
            <AdvancedMarker position={{ lat: latitude, lng: longitude }} />
          </Map>
        </APIProvider>
      </Location>
    </Container>
  );
}

export default InfosRealestate;
