import styled from "styled-components";

export const Container = styled.div`
  width: 1128px;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;

  @media (max-width: 1200px) {
    padding: 20px 20px;
  }

  .carousel {
    width: 100%;
    height: 397px;
    margin-bottom: 22px;
    display: flex;
    justify-items: center;
    border-bottom: 5px solid #14202e;
  }

  .carousel img {
    width: 100%;
    height: auto;
  }
`;

export const TypeRealestate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;

  h2 {
    font-family: "Playfair Display", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #14202e;
    margin-bottom: 10px;
  }

  span {
    font-family: "Montserrat", sans-serif;
    color: #14202e;
    font-size: 16px;
  }

  button {
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const Value = styled.div`
  font-family: "Playfair Display", sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #c29b40;
  margin-bottom: 40px;

  @media (max-width: 375px) {
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  img {
    height: 30px;
    width: 30px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`;

export const Location = styled.div`
  height: 423px;
`;

export const RealestateInfo = styled.div`
  margin-bottom: 32px;
`;

export const RealestateDetail = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #14202e;
  display: flex;
  justify-content: space-between;
  align-items: start;

  p {
    width: 648px;

    @media (max-width: 688px) {
      width: 100%;
    }
  }

  button {
    display: none;

    @media (max-width: 600px) {
      display: flex;
      margin: 20px 0 20px;
    }
  }

  @media (max-width: 1030px) {
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 10px;
  }
`;

export const ImageRealestate = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 397px;
  width: 100%;
`;
