import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ScrollToTop from "../components/scrollToTop";

import Home from "./home";
import InformationsRealestate from "./infosRealestate";
import AboutUs from "./aboutUs";
import PrivacyPolicy from "./privacyPolicy";
import SingularRealtyHighlights from "./singularRealtyHighlights";
import RealestateHighlights from "./realestateHighlights";
import Realestate from "./realestates";
import RealestateSearchResults from "../components/realestateSearchResults";

export const Root = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/imoveis/:id" element={<InformationsRealestate />} />
        <Route path="/sobre-nos" element={<AboutUs />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route
          path="/destaques-singular-realty"
          element={<SingularRealtyHighlights />}
        />
        <Route
          path="/oportunidades-especiais"
          element={<RealestateHighlights />}
        />
        <Route path="/imoveis/categoria/:name" element={<Realestate />} />
        <Route path="/buscar-imoveis" element={<RealestateSearchResults />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default Root;
