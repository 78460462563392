import React from "react";

import Button from "../button";

import {
  ButtonContainer,
  CardContainer,
  InfoText,
  Location,
  Title,
} from "./styles";

const Card = ({
  title,
  location,
  size,
  garage,
  suite,
  bedroom,
  onButtonClick,
}) => {
  return (
    <CardContainer>
      <div>
        <Title>{title}</Title>
        <Location>{location}</Location>
      </div>
      <div>
        <InfoText>{size} m²</InfoText>
        <InfoText>{garage} garagem(s)</InfoText>
        <InfoText>{suite} suíte(s)</InfoText>
        <InfoText>{bedroom} dormitório(s)</InfoText>
      </div>
      <ButtonContainer>
        <Button backgroundColor="#C29B40" onClick={onButtonClick} icon={false}>
          Saiba Mais
        </Button>
      </ButtonContainer>
    </CardContainer>
  );
};

export default Card;
