import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";

import RealestateCardTwo from "../realestateCardTwo";
import TitleHeader from "../titleHeader";
import Button from "../button";

import { Container, ContainerRealEstate, Content } from "./styles";

const RealestateHighlights = () => {
  const navigate = useNavigate();
  const [realestate, setRealEstate] = useState();

  const renderRealEstate = async () => {
    const data = {
      min_price_per_private_square_meter: 20000,
      finality: ["residential"],
    };

    try {
      await api
        .get("/buildings", {
          params: data,
        })
        .then((response) => {
          setRealEstate(response.data.buildings.slice(0, 4));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  useEffect(() => {
    renderRealEstate();
  }, []);

  return (
    <Container>
      <Content>
        <TitleHeader title="Oportunidades especiais" className="title" />

        <ContainerRealEstate>
          {realestate &&
            realestate.map((realestate, index) => {
              return (
                <RealestateCardTwo
                  image={realestate.default_image["200x140"]}
                  location={realestate.address.area}
                  squareMeters={realestate.min_area}
                  parkingSpaces={realestate.min_parking}
                  dormitory={realestate.min_bedrooms}
                  value={realestate.min_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  name={realestate.name}
                  suite={realestate.min_suites}
                  onClick={() => navigate(`/imoveis/${realestate.id}`)}
                  key={index}
                />
              );
            })}
        </ContainerRealEstate>
        <Button
          backgroundColor="#14202E"
          onClick={() => navigate("/oportunidades-especiais")}
        >
          VEJA MAIS OPÇÕES
        </Button>
      </Content>
    </Container>
  );
};

export default RealestateHighlights;
