import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sideBar";
import TitleHeader from "../../components/titleHeader";
import Carousel from "../../components/carousel";

import RealestateOne from "../../assets/realestate/realestateOne.jpg";
import RealestateTwo from "../../assets/realestate/realestateTwo.jpg";
import RealestateTwhree from "../../assets/realestate/realestateThree.jpg";

import {
  HeroSection,
  Container,
  ContainerCarousel,
  ContainerSideBar,
  ContainerSideBarMobile,
} from "./styles";

import SingularRealtyHighlights from "../../components/singularRealtyHighlights";
import FindYourRealestate from "../../components/findYourRealestate";
import RealestateHighlights from "../../components/realestateHighlights";

const Home = () => {
  const realestates = [
    {
      id: 43576,
      title: "Apartamento",
      location: "Cerqueira César",
      size: 278,
      garage: 3,
      suite: 3,
      bedroom: 3,
      image: RealestateOne,
    },
    {
      id: 57676,
      title: "Apartamento",
      location: "Vila Nova Conceição",
      size: "253 a 409",
      garage: "3 a 4",
      suite: 3,
      bedroom: 3,
      image: RealestateTwo,
    },
    {
      id: 38326,
      title: "Apartamento",
      location: "Itaim Bibi",
      size: "250",
      garage: 3,
      suite: 2,
      bedroom: 2,
      image: RealestateTwhree,
    },
  ];
  return (
    <Container>
      <HeroSection>
        <ContainerSideBar>
          <Sidebar />
        </ContainerSideBar>

        <ContainerSideBarMobile>
          <Header />
        </ContainerSideBarMobile>

        <ContainerCarousel>
          <TitleHeader
            title="Imóveis singulares"
            subtitle="para uma vida extraordinária"
            className="title"
          />
          <Carousel realestates={realestates} />
        </ContainerCarousel>
      </HeroSection>

      <SingularRealtyHighlights />
      <FindYourRealestate />
      <RealestateHighlights />

      <Footer />
    </Container>
  );
};

export default Home;
