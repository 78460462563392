import styled from "styled-components";

export const FooterContainer = styled.footer`
  width: 100%;
  padding-top: 30px;
  border-top: 5px solid #14202e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
`;

export const FooterContent = styled.div`
  width: 100%;
  max-width: 1128px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1128px) {
    padding: 10px 10px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;

    margin-bottom: 30px;
  }
`;

export const Logo = styled.img`
  width: 264px;
  height: 85px;
`;

export const InfoSection = styled.div`
  display: flex;
  gap: 20px;
  a {
    text-decoration: none;
    color: #14202e;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ContactInfo = styled.address`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 500px) {
    margin-bottom: 32px;
  }
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #14202e;
  font-family: "Montserrat", sans-serif;
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 500px) {
    flex-direction: row;

    a {
      display: none;
    }
  }
`;

export const SocialLinkItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #14202e;
  font-family: "Montserrat", sans-serif;
`;

export const ContainerCompanies = styled.div`
  height: 153px;
  background-color: #14202e;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 18px 0px 16px;
  margin-top: 20px;

  @media (max-width: 800px) {
    height: 100%;
  }
`;

export const Companies = styled.div`
  width: 100%;
  max-width: 839px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const YMK = styled.figure`
  @media (max-width: 800px) {
    margin-bottom: 47px;
  }
`;

export const LogoYMK = styled.img`
  width: 108px;
  height: 91px;
`;

export const ContentLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto;
  gap: 10px;
  width: 80%;
  justify-items: center;
  align-items: center;
  height: 100%;

  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
  }
`;

export const LogoCompanie = styled.img`
  width: 90px;
  height: 30px;

  @media (max-width: 500px) {
    width: 80px;
    height: 30px;
  }
`;

export const LegalNotice = styled.div`
  width: 100%;
  max-width: 1128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #14202e;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  padding: 15px 0 15px;
  height: 100%;

  a {
    text-decoration: none;
    color: #14202e;
    font-weight: bold;
  }

  @media (max-width: 1128px) {
    padding: 10px 10px;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    text-align: center;
    font-size: 10px;
  }
`;
