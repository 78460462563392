import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchForm from "../search";

import {
  Container,
  Footer,
  FooterItem,
  Logo,
  Menu,
  MenuItem,
  MenuSeacrh,
  SearchBar,
  SocialIcons,
} from "./styles";

import {
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { LuMail } from "react-icons/lu";

import LogoSideBar from "../../assets/logoSideBar.svg";
import ButtonSearch from "../../assets/buttonSearch.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);

  const toggleDisplay = () => {
    setDisplay((prevDisplay) => !prevDisplay);
  };

  return (
    <Container>
      <Menu>
        <Logo src={LogoSideBar} alt="logo" />
        <MenuItem>LANÇAMENTOS</MenuItem>
        <MenuItem onClick={() => navigate("/imoveis/categoria/obras")}>
          OBRAS
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/imoveis/categoria/pronto-para-morar")}
        >
          PRONTO PARA MORAR
        </MenuItem>
        <MenuItem>PRAIA</MenuItem>
        <MenuItem>CAMPO</MenuItem>
        <MenuItem
          onClick={() => window.open("https://realestate4me.com.br/", "_blank")}
        >
          INTERNACIONAL
        </MenuItem>
        <MenuItem onClick={() => navigate("/imoveis/categoria/coberturas")}>
          COBERTURAS
        </MenuItem>
        <MenuItem>USADOS</MenuItem>

        <SearchBar
          src={ButtonSearch}
          alt="buscar_imóveis"
          onClick={toggleDisplay}
        />
      </Menu>

      <div>
        <SocialIcons>
          <FaInstagram />
          <FaFacebook />
          <FaLinkedin />
          <FaWhatsapp />
          <LuMail />
        </SocialIcons>
        <Footer>
          <FooterItem to="/sobre-nos">Sobre nós</FooterItem>
          <FooterItem
            onClick={() =>
              document
                .querySelector("footer")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Contato
          </FooterItem>
        </Footer>
      </div>

      <MenuSeacrh display={display}>
        <SearchForm />
      </MenuSeacrh>
    </Container>
  );
};

export default Sidebar;
