// ScrollToTop.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";

const ScrollToTopContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <ScrollToTopContainer />;
};

export default ScrollToTop;
