import {
  FooterContainer,
  FooterContent,
  InfoSection,
  Logo,
  ContactInfo,
  ContactItem,
  SocialLinks,
  SocialLinkItem,
  ContainerCompanies,
  Companies,
  YMK,
  LogoYMK,
  ContentLogos,
  LogoCompanie,
  LegalNotice,
} from "./styles";

import LogoImage from "../../assets/logo.svg";

import YmkLogo from "../../assets/companies/ymk.svg";
import Estoque from "../../assets/companies/estoque.svg";
import Bladoom from "../../assets/companies/bladoom.svg";
import Urblar from "../../assets/companies/urblar.svg";
import Imovelpra from "../../assets/companies/imovelpra.svg";
import Realestate from "../../assets/companies/realestate.svg";
import Credimov from "../../assets/companies/credimov.svg";
import Investimov from "../../assets/companies/investimov.svg";
import Rai from "../../assets/companies/rai.svg";
import YmkAgro from "../../assets/companies/ymk_agro.svg";
import YmkCapital from "../../assets/companies/ymk_capital.svg";
import YmkCorp from "../../assets/companies/ymk_corp.svg";
import YmkLand from "../../assets/companies/ymk_land.svg";

import {
  FaPhoneAlt,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <FooterContainer id="footer">
      <FooterContent>
        <Logo src={LogoImage} alt="Singular Realty logo" />

        <InfoSection>
          <ContactInfo>
            <ContactItem>
              <FaPhoneAlt size={20} aria-hidden="true" />
              <a href="tel:+551139957808">(11) 3995-7808</a>
            </ContactItem>
            <ContactItem>
              <FaWhatsapp size={20} aria-hidden="true" />
              <a
                href="https://wa.me/5511983344053"
                target="_blank"
                rel="noopener noreferrer"
              >
                (11) 98334-4053
              </a>
            </ContactItem>
            <ContactItem>
              <LuMail size={20} aria-hidden="true" />
              <a href="mailto:contato@singularrealty.com.br">
                contato@singularrealty.com.br
              </a>
            </ContactItem>
            <ContactItem>
              <FaLocationDot size={20} aria-hidden="true" />
              Praça Santo Agostinho, 70 <br />
              Aclimação - São Paulo - SP CEP 01533-070
            </ContactItem>
          </ContactInfo>

          <SocialLinks>
            <SocialLinkItem>
              <FaFacebook size={20} aria-hidden="true" />
              <a
                href="https://www.facebook.com/singularrealty"
                target="_blank"
                rel="noopener noreferrer"
              >
                singularrealty
              </a>
            </SocialLinkItem>
            <SocialLinkItem>
              <FaInstagram size={20} aria-hidden="true" />
              <a
                href="https://www.instagram.com/singularrealty"
                target="_blank"
                rel="noopener noreferrer"
              >
                singularrealty
              </a>
            </SocialLinkItem>
            <SocialLinkItem>
              <FaLinkedin size={20} aria-hidden="true" />
              <a
                href="https://www.linkedin.com/company/singularrealty"
                target="_blank"
                rel="noopener noreferrer"
              >
                singularrealty
              </a>
            </SocialLinkItem>
          </SocialLinks>
        </InfoSection>
      </FooterContent>

      <ContainerCompanies>
        <Companies>
          <YMK>
            <a target="_blank" href="https://ymk.com.br/">
              <LogoYMK src={YmkLogo} alt="logo" />
            </a>
          </YMK>
          <ContentLogos>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.estoqueimovel.com.br/"
            >
              <LogoCompanie src={Estoque} />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bladoom.com/"
            >
              <LogoCompanie src={Bladoom} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://urblar.com.br/"
            >
              <LogoCompanie src={Urblar} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.imovelpra.com.br/"
            >
              <LogoCompanie src={Imovelpra} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://credimov.com.br/"
            >
              <LogoCompanie src={Credimov} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://investimov.com.br/"
            >
              <LogoCompanie src={Investimov} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://realestate4me.com.br/"
            >
              <LogoCompanie src={Realestate} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://rainvestimentos.com.br/"
            >
              <LogoCompanie src={Rai} />
            </a>
            <a rel="noopener noreferrer" href="#">
              <LogoCompanie src={YmkAgro} />
            </a>
            <a rel="noopener noreferrer" href="#">
              <LogoCompanie src={YmkCapital} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ymkcorp.com.br/"
            >
              <LogoCompanie src={YmkCorp} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ymkland.com.br/"
            >
              <LogoCompanie src={YmkLand} />
            </a>
          </ContentLogos>
        </Companies>
      </ContainerCompanies>
      <LegalNotice>
        <div>
          &copy; {new Date().getFullYear()} Singular Realty | TODOS DIREITOS
          RESERVADOS
        </div>
        <div>
          Desenvolvido por{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ypb.com.br/"
          >
            YPB MARKETING DIGITAL
          </a>
        </div>
      </LegalNotice>
    </FooterContainer>
  );
};

export default Footer;
