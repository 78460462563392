import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Footer from "../../components/footer";
import Header from "../../components/header";
import InfosRealestate from "../../components/infosRealestate";
import api from "../../api";

import { Container, Content, LoadingMessage } from "./styles";

const InformationsRealestate = () => {
  const { id } = useParams();
  const [dataRealestate, setDataRealestate] = useState();
  const [loading, setLoading] = useState(false);

  const RenderRealestate = async () => {
    setLoading(true);
    try {
      await api
        .get(`buildings/${id}`)
        .then((response) => {
          setDataRealestate(response.data);
        })
        .catch((error) => {
          console.log("Erro: ", error);
        });
    } catch (error) {
      console.log("Erro: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    RenderRealestate();
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        {loading && <LoadingMessage>CARREGANDO...</LoadingMessage>}
        {dataRealestate && (
          <InfosRealestate
            name={dataRealestate.name}
            location={dataRealestate.address.area}
            value={dataRealestate.min_price.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
            squareMeters={dataRealestate.min_area}
            suite={dataRealestate.min_suites}
            parkingSpaces={dataRealestate.min_parking}
            bedroom={dataRealestate.min_bedrooms}
            description={dataRealestate.description}
            latitude={dataRealestate.address.latitude}
            longitude={dataRealestate.address.longitude}
          />
        )}
      </Content>

      <Footer />
    </Container>
  );
};

export default InformationsRealestate;
