import styled from "styled-components";

export const Container = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 50px;
`;

export const Content = styled.div`
  width: 1128px;
  height: 100%;
  padding-top: 41px;
  padding-bottom: 51px;

  .title {
    margin-bottom: 33px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContainerCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: 880px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
