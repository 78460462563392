import styled from "styled-components";

import { NavLink } from "react-router-dom";

export const Container = styled.div`
  width: 200px;
  height: 90vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  justify-content: space-between;
  border-right: 1px solid white;
  z-index: 999;
`;

export const Logo = styled.img`
  margin-top: 5px;
  margin-bottom: 25px;
  width: 165px;
  height: 41px;
`;

export const Menu = styled.ul`
  list-style: none;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  padding-left: 0px;
`;

export const MenuItem = styled.li`
  padding: 7px 0;
  cursor: pointer;
`;

export const SearchBar = styled.img`
  margin: 20px 0;
  cursor: pointer;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;

  svg {
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
`;

export const FooterItem = styled(NavLink)`
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: white;
`;

export const MenuSeacrh = styled.div`
  position: absolute;
  top: 0px;
  left: 200px;

  display: ${(props) => (props.display ? "block" : "none")};
`;
